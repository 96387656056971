@tailwind base;
@tailwind components;
@tailwind utilities;

.active {
  color: #36be21;
}

.text-n1 {
  font-family: "DM Serif Display", serif;
}

.text-h1,
.text-h2,
.text-h3,
.text-h4,
.text-h5,
.text-h6 {
  font-family: "Lora", serif;
}

.text-l1,
.text-l2,
.text-l3,
.text-l4,
.text-l5,
.text-l6,
.text-p1,
.text-p2,
.text-p3 {
  font-family: "Raleway", sans-serif;
}
.no-scroll {
  overflow: hidden;
}
